import { Feature, isFeatureEnabled } from "@/helpers/featureFlagHelpers";
import { setTypeChart } from "@/redux/slices/config";
import {
    Call,
    CloudUpload,
    Contacts,
    Error as ErrorIcon,
    Inventory2,
    PhoneInTalk,
    RecordVoiceOver,
    Sell,
    TransferWithinAStation,
} from "@mui/icons-material";
import {
    Alert,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import NextLink from "next/link";
import { useEffect, useState } from "react";
import Linkify from 'react-linkify';
import { connect, useDispatch } from "react-redux";
import {
    authenticate,
    getCompany,
    getVarUrl,
    isAuth,
    reloadtoken,
} from "../../actions/auth";
import TextPanel from "../../components/TextPanel";
import {
    setSelectedUser,
    setUsarAyer,
    setUsers
} from "../../redux/actions/main";
import Module from "../core/Module";
import InstanceListModule from "../evaluate/InstanceListModule";
import Goal from "../goal/Goal";
import AlertGroupReport from "../goal/alert/AlertGroupReport";
import AlertReport from "../goal/alert/AlertUserReport";
import Performance from "../goal/performance-chart";
import DashboardCharts from "./DashboardCharts";
import GraphItem from "./GraphItem";
import ComissionModule from './comission/ComissionModule';
import MeetingsReport from "./meeting-report/MeetingsReport";
import PanelInfo from "./panel_info";
import UserInformation from "./user-information";

function Home({ selectedUser, selectedGroup, setUsarAyer, usarAyer }) {
    var [usuario, setUsuario] = useState<any>({});
    var [vendedor, setVendedor] = useState("");
    var [grupo, setGrupo] = useState({});
    var [rol, setRol] = useState<string | undefined>();
    var [panel] = useState(PanelInfo);
    const [showAvisos, setShowAvisos] = useState(true)

    const dispatch = useDispatch();
    const isEnabledComissions = isFeatureEnabled(Feature.ENABLE_COMISSIONS_FEATURE);

    useEffect(() => {
        let user = isAuth();
        let groupi;

        const roles = user.roles
        if (user) {
            let modeOfChart = 'detailed'

            dispatch(setTypeChart(modeOfChart))

            setUsuario(user);
            if (roles[0] == "admin") setRol("comercial");
            if (roles[0] != "admin") {
                if (roles.includes('comercial')) {
                    setRol('comercial');
                } else if (roles.includes('super')) {
                    setRol('super');
                } else {
                    setRol(roles[0]);
                }
            }
            setVendedor(user.role == 0 ? user : {});
            if (user.group.ancestors.length > 0) {
                setGrupo(user.group.ancestors[0]);
                groupi = user.group.ancestors[0];
            } else {
                setGrupo(user.group);
                groupi = user.group;
            }
            reloadtoken().then((res) => {
                if (!res || res.error) return;
                if (res.user.group.ancestors.length > 0) {
                    setGrupo(res.user.group.ancestors[0]);
                } else {
                    setGrupo(res.user.group);
                }
                return authenticate(res, () => { });
            });
        }
    }, []);

    return (
        <section className="bg-[#f6f6f6]">
            <div className="flex flex-col m-auto max-w-[1600px] relative w-[1] sm:gap-4 gap-3">
                <UserInformation
                    panel={panel}
                    rol={rol}
                    selectedGroup={selectedGroup}
                    selectedUser={selectedUser}
                    user={usuario}
                />
                {panel
                    .map((p) => {
                        if (
                            selectedUser
                                ? p.role.indexOf("user") < 0
                                : selectedGroup
                                    ? p.role.indexOf("super") < 0
                                    : p.role.indexOf(rol as string) < 0
                        )
                            return "";



                        switch (p.template) {
                            case "text":
                                return (
                                    <TextPanel
                                        key={p._id}
                                        title={p.title}
                                        user={
                                            selectedUser
                                                ? selectedUser
                                                : usuario
                                        }
                                    >
                                        {p.content.text}
                                    </TextPanel>
                                );
                            case "report":
                                return (
                                    <Module title={p.title}>
                                        <iframe
                                            frameBorder="0"
                                            width="100%"
                                            height={p.content.height}
                                            src={getVarUrl(
                                                p.content.url,
                                                selectedUser
                                                    ? selectedUser
                                                    : usuario
                                            )}
                                        ></iframe>
                                    </Module>
                                );
                            case "long":

                                let user_usando_per_alert = selectedUser
                                    ? selectedUser._id
                                    : rol == "super" || rol == "comercial"
                                        ? undefined
                                        : usuario._id;

                                let groupToShow = selectedGroup
                                    ? selectedGroup._id
                                    : selectedUser ? undefined : usuario.group._id;

                                const useUserSelected = selectedUser
                                    ? selectedUser._id :
                                    rol == "super" ||
                                        rol == "comercial"
                                        ? "all" :
                                        usuario._id

                                return (
                                    <div className={`md:mx-6 h-max grid ${isEnabledComissions ? 'md:grid-cols-8' : ' md:grid-cols-2'} grid-cols-1 gap-y-3 md:gap-[2px]`}>
                                        <div className={`w-full ${isEnabledComissions ? 'col-span-3' : ''} h-full`}>
                                            {
                                                isEnabledComissions
                                                    ? <ComissionModule
                                                        userSelected={useUserSelected}
                                                        groupSelected={groupToShow}
                                                    />
                                                    : showAvisos
                                                        ? <InstanceListModule
                                                            groupFilter={
                                                                selectedGroup
                                                                    ? selectedGroup._id :
                                                                    undefined
                                                            }
                                                            clickedAvisos={() => setShowAvisos(false)}
                                                            id={useUserSelected}
                                                        /> : groupToShow
                                                            ? <AlertGroupReport
                                                                clickedAvisos={() => setShowAvisos(true)}
                                                                group={groupToShow}
                                                            />
                                                            : <AlertReport
                                                                clickedAvisos={() => setShowAvisos(true)}
                                                                user={user_usando_per_alert}
                                                            />
                                            }
                                        </div>
                                        <div className={`w-full h-full ${isEnabledComissions ? 'col-span-5' : ''}`}>
                                            <Module
                                                cardStyle="lg:rounded-l-none md:mx-0 mx-2 flex-grow xl:rounded-l-none 2xl:rounded-l-none"
                                                title={p.title}
                                                sx={{ height: "100%" }}
                                                action={
                                                    <ToggleButtonGroup
                                                        value={
                                                            usarAyer
                                                                ? "si"
                                                                : "no"
                                                        }
                                                        exclusive
                                                    >
                                                        <ToggleButton
                                                            value={"si"}
                                                            onClick={() =>
                                                                setUsarAyer(
                                                                    false
                                                                )
                                                            }
                                                            sx={{
                                                                py: 0.25,
                                                                "&:hover": {
                                                                    color: "white",
                                                                    borderColor:
                                                                        "#2b7cd2",
                                                                    background:
                                                                        "#2b7cd2",
                                                                },
                                                                color: usarAyer
                                                                    ? "#0080ff !important"
                                                                    : "white",
                                                                borderColor:
                                                                    usarAyer
                                                                        ? "#0080ff"
                                                                        : "#0080ff",
                                                                background:
                                                                    usarAyer
                                                                        ? "#fff !important"
                                                                        : "#0080ff",
                                                            }}
                                                        >
                                                            Hoy
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            value={"no"}
                                                            onClick={() =>
                                                                setUsarAyer(
                                                                    true
                                                                )
                                                            }
                                                            sx={{
                                                                py: 0.25,
                                                                "&:hover": {
                                                                    color: "white",
                                                                    borderColor:
                                                                        "#2b7cd2",
                                                                    background:
                                                                        "#2b7cd2",
                                                                },
                                                                color: !usarAyer
                                                                    ? "#0080ff !important"
                                                                    : "white",
                                                                borderColor:
                                                                    !usarAyer
                                                                        ? "#0080ff"
                                                                        : "#0080ff",
                                                                background:
                                                                    !usarAyer
                                                                        ? "#fff !important"
                                                                        : "#0080ff",
                                                            }}
                                                        >
                                                            Ayer
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                }
                                            >
                                                <div className="flex flex-wrap">
                                                    <div className="w-full">
                                                        <GraphItem
                                                            filters={[
                                                                "Fecha",
                                                                "Agente",
                                                                "Origen",
                                                            ]}
                                                            icon={<Contacts />}
                                                            title="Contactos creados"
                                                            source="manual_contacts"
                                                            dateField="created"
                                                            userId={
                                                                selectedUser
                                                                    ? selectedUser._id
                                                                    : rol ==
                                                                        "super" ||
                                                                        rol ==
                                                                        "comercial"
                                                                        ? "all"
                                                                        : usuario._id
                                                            }
                                                            groupId={
                                                                selectedGroup
                                                                    ? selectedGroup._id
                                                                    : undefined
                                                            }
                                                            columns={[
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "created",
                                                                    headerName:
                                                                        "Fecha y hora",
                                                                    description:
                                                                        "Fecha y hora de creación",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return (
                                                                                moment(
                                                                                    new Date(
                                                                                        params.value
                                                                                    )
                                                                                ).format(
                                                                                    "L"
                                                                                ) +
                                                                                " " +
                                                                                moment(
                                                                                    new Date(
                                                                                        params.value
                                                                                    )
                                                                                ).format(
                                                                                    "LT"
                                                                                )
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "phones",
                                                                    headerName:
                                                                        "Teléfono",
                                                                    description:
                                                                        "Teléfono del agente",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params
                                                                                .value[0];
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "agent",
                                                                    headerName:
                                                                        "Vendedor",
                                                                    description:
                                                                        "Nombre del agente",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return `${params.value.firstName} ${params.value.lastName}`;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "firstName",
                                                                    headerName:
                                                                        "Nombre contacto",
                                                                    description:
                                                                        "Nombre del contacto",
                                                                    width: 150,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "lastName",
                                                                    headerName:
                                                                        "Apellido Contacto",
                                                                    description:
                                                                        "Apellido del contacto",
                                                                    width: 150,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "leads",
                                                                    headerName:
                                                                        "Orgien",
                                                                    description:
                                                                        "Grupo del contacto",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            let res =
                                                                                params
                                                                                    .value[0]
                                                                                    .source;
                                                                            res =
                                                                                res[0].toUpperCase() +
                                                                                res
                                                                                    .substr(
                                                                                        1
                                                                                    )
                                                                                    .toLowerCase();
                                                                            res.slice(
                                                                                1,
                                                                                1
                                                                            );
                                                                            return res;
                                                                        },
                                                                },
                                                            ]}
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <GraphItem
                                                            icon={<Call />}
                                                            filters={[
                                                                "Fecha",
                                                                "Vendedor",
                                                                "TipoLlamada",
                                                            ]}
                                                            title="Llamadas"
                                                            source="calls"
                                                            dateField="timestamp"
                                                            userId={
                                                                selectedUser
                                                                    ? selectedUser._id
                                                                    : rol ==
                                                                        "super" ||
                                                                        rol ==
                                                                        "comercial"
                                                                        ? "all"
                                                                        : usuario._id
                                                            }
                                                            groupId={
                                                                selectedGroup
                                                                    ? selectedGroup._id
                                                                    : undefined
                                                            }
                                                            columns={[
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "timestamp",
                                                                    headerName:
                                                                        "Fecha y hora",
                                                                    description:
                                                                        "Fecha y hora",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return (
                                                                                moment(
                                                                                    new Date(
                                                                                        parseInt(
                                                                                            params.value
                                                                                        )
                                                                                    )
                                                                                ).format(
                                                                                    "L"
                                                                                ) +
                                                                                " " +
                                                                                moment(
                                                                                    new Date(
                                                                                        parseInt(
                                                                                            params.value
                                                                                        )
                                                                                    )
                                                                                ).format(
                                                                                    "LT"
                                                                                )
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "phoneNumber",
                                                                    headerName:
                                                                        "Teléfono",
                                                                    description:
                                                                        "Teléfono",
                                                                    width: 150,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "prospectName",
                                                                    headerName:
                                                                        "Nombre Contacto",
                                                                    description:
                                                                        "Nombre Contacto",
                                                                    width: 150,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "duration",
                                                                    headerName:
                                                                        "Duracion",
                                                                    description:
                                                                        "Duracion en minutos de la llamada",
                                                                    width: 150,

                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            let dateObj =
                                                                                new Date(
                                                                                    params.value *
                                                                                    1000
                                                                                );
                                                                            let hours =
                                                                                dateObj.getUTCHours();
                                                                            let minutes =
                                                                                dateObj.getUTCMinutes();
                                                                            let seconds =
                                                                                dateObj.getSeconds();

                                                                            let timeString =
                                                                                hours
                                                                                    .toString()
                                                                                    .padStart(
                                                                                        2,
                                                                                        "0"
                                                                                    ) +
                                                                                ":" +
                                                                                minutes
                                                                                    .toString()
                                                                                    .padStart(
                                                                                        2,
                                                                                        "0"
                                                                                    ) +
                                                                                ":" +
                                                                                seconds
                                                                                    .toString()
                                                                                    .padStart(
                                                                                        2,
                                                                                        "0"
                                                                                    );

                                                                            return timeString;
                                                                        },
                                                                },

                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "type",
                                                                    headerName:
                                                                        "Tipo",
                                                                    description:
                                                                        "Tipo",
                                                                    width: 100,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            let value =
                                                                                "";
                                                                            switch (
                                                                            params.value
                                                                            ) {
                                                                                case "INCOMING":
                                                                                    value =
                                                                                        "Entrante";
                                                                                    break;
                                                                                case "MISSED":
                                                                                    value =
                                                                                        "Perdida";
                                                                                    break;
                                                                                case "UNKNOWN":
                                                                                    value =
                                                                                        "Desconocida";
                                                                                    break;
                                                                                case "OUTGOING":
                                                                                    value =
                                                                                        "Saliente";
                                                                                    break;
                                                                            }
                                                                            return value;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "user",
                                                                    headerName:
                                                                        "Vendedor",
                                                                    description:
                                                                        "Vendedor",
                                                                    width: 200,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return (
                                                                                params
                                                                                    .value
                                                                                    .name +
                                                                                " " +
                                                                                params
                                                                                    .value
                                                                                    .lastname
                                                                            );
                                                                        },
                                                                },
                                                            ]}
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <GraphItem
                                                            groupId={
                                                                selectedGroup
                                                                    ? selectedGroup._id
                                                                    : undefined
                                                            }
                                                            columns={[
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "timestamp",
                                                                    headerName:
                                                                        "Fecha",
                                                                    description:
                                                                        "Fecha",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return new Date(
                                                                                params.value
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "phoneNumber",
                                                                    headerName:
                                                                        "Teléfono",
                                                                    description:
                                                                        "Teléfono",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "timestamp",
                                                                    headerName:
                                                                        "Duracion",
                                                                    description:
                                                                        "Duracion",
                                                                    width: 100,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            // let dateObj = new Date(params.value * 1000);
                                                                            // let hours = dateObj.getUTCHours();
                                                                            // let minutes = dateObj.getUTCMinutes();
                                                                            // let seconds = dateObj.getSeconds();

                                                                            // let timeString = hours.toString().padStart(2, '0') + ':' +
                                                                            //     minutes.toString().padStart(2, '0') + ':' +
                                                                            //     seconds.toString().padStart(2, '0');

                                                                            // return timeString;
                                                                            return (
                                                                                params.value +
                                                                                ""
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "user",
                                                                    headerName:
                                                                        "Nombre",
                                                                    description:
                                                                        "Nombre",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return (
                                                                                params
                                                                                    .value
                                                                                    .name +
                                                                                " " +
                                                                                params
                                                                                    .value
                                                                                    .lastname
                                                                            );
                                                                        },
                                                                },
                                                            ]}
                                                            icon={
                                                                <PhoneInTalk />
                                                            }
                                                            filters={[
                                                                "Fecha",
                                                                "Vendedor",
                                                            ]}
                                                            title="Minutos al aire"
                                                            source="calls"
                                                            action="sum"
                                                            field="duration"
                                                            dateField="timestamp"
                                                            divide={60}
                                                            userId={
                                                                selectedUser
                                                                    ? selectedUser._id
                                                                    : rol ==
                                                                        "super" ||
                                                                        rol ==
                                                                        "comercial"
                                                                        ? "all"
                                                                        : usuario._id
                                                            }
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <GraphItem
                                                            groupId={
                                                                selectedGroup
                                                                    ? selectedGroup._id
                                                                    : undefined
                                                            }
                                                            columns={[
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "date",
                                                                    headerName:
                                                                        "Fecha",
                                                                    description:
                                                                        "Fecha",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return moment(
                                                                                new Date(
                                                                                    params.value
                                                                                )
                                                                            ).format(
                                                                                "L"
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "phone",
                                                                    headerName:
                                                                        "Teléfono",
                                                                    description:
                                                                        "Teléfono",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "maxDuration",
                                                                    headerName:
                                                                        "Duracion",
                                                                    description:
                                                                        "Duracion",
                                                                    width: 100,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            let dateObj =
                                                                                new Date(
                                                                                    params.value *
                                                                                    1000
                                                                                );
                                                                            let hours =
                                                                                dateObj.getUTCHours();
                                                                            let minutes =
                                                                                dateObj.getUTCMinutes();
                                                                            let seconds =
                                                                                dateObj.getSeconds();

                                                                            let timeString =
                                                                                hours
                                                                                    .toString()
                                                                                    .padStart(
                                                                                        2,
                                                                                        "0"
                                                                                    ) +
                                                                                ":" +
                                                                                minutes
                                                                                    .toString()
                                                                                    .padStart(
                                                                                        2,
                                                                                        "0"
                                                                                    ) +
                                                                                ":" +
                                                                                seconds
                                                                                    .toString()
                                                                                    .padStart(
                                                                                        2,
                                                                                        "0"
                                                                                    );

                                                                            return timeString;
                                                                        },
                                                                },

                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "validity",
                                                                    headerName:
                                                                        "Valido",
                                                                    description:
                                                                        "Valido",
                                                                    width: 100,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            if (
                                                                                params.value
                                                                            ) {
                                                                                return "Valido";
                                                                            } else {
                                                                                return "Invalido";
                                                                            }
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "user",
                                                                    headerName:
                                                                        "Vendedor",
                                                                    description:
                                                                        "Nombre",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return (
                                                                                params
                                                                                    .value
                                                                                    .name +
                                                                                " " +
                                                                                params
                                                                                    .value
                                                                                    .lastname
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "noteContent",
                                                                    headerName:
                                                                        "Nota",
                                                                    description:
                                                                        "Nota",
                                                                    width: 200,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "noteDate",
                                                                    headerName:
                                                                        "Fecha de nota",
                                                                    description:
                                                                        "Fecha de nota",
                                                                    width: 200,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value ? moment(params.value).format("L LT") : "";
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "callDate",
                                                                    headerName:
                                                                        "Fecha de llamada",
                                                                    description:
                                                                        "Fecha de llamada",
                                                                    width: 200,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value ? moment(params.value).format("L LT") : "";
                                                                        },
                                                                },
                                                            ]}
                                                            icon={
                                                                <RecordVoiceOver />
                                                            }
                                                            filters={[
                                                                "Fecha",
                                                                "Vendedor",
                                                                "Duracion",
                                                            ]}
                                                            format={
                                                                "YYYY/MM/DD"
                                                            }
                                                            title="Seguimientos"
                                                            source="tracing"
                                                            dateField="date"
                                                            userId={
                                                                selectedUser
                                                                    ? selectedUser._id
                                                                    : rol ==
                                                                        "super" ||
                                                                        rol ==
                                                                        "comercial"
                                                                        ? "all"
                                                                        : usuario._id
                                                            }
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <GraphItem
                                                            groupId={
                                                                selectedGroup
                                                                    ? selectedGroup._id
                                                                    : undefined
                                                            }
                                                            columns={[
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "date_sale",
                                                                    headerName:
                                                                        "Fecha de venta",
                                                                    description:
                                                                        "Fecha de la venta",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return moment(
                                                                                new Date(
                                                                                    params.value
                                                                                )
                                                                            ).format(
                                                                                "L"
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "date",
                                                                    headerName:
                                                                        "Fecha ingreso",
                                                                    description:
                                                                        "Fecha de ingreso",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return moment(
                                                                                new Date(
                                                                                    params.value
                                                                                )
                                                                            ).format(
                                                                                "L"
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "user",
                                                                    headerName:
                                                                        "Vendedor",
                                                                    description:
                                                                        "Vendedor",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return (
                                                                                params
                                                                                    .value
                                                                                    .name +
                                                                                " " +
                                                                                params
                                                                                    .value
                                                                                    .lastname
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "ticket_id",
                                                                    headerName:
                                                                        "ID",
                                                                    description:
                                                                        "ID",
                                                                    width: 130,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "ticket_sale_id",
                                                                    headerName:
                                                                        "ID Venta",
                                                                    description:
                                                                        "ID Venta",
                                                                    width: 130,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "phone",
                                                                    headerName:
                                                                        "Teléfono",
                                                                    description:
                                                                        "Teléfono",
                                                                    width: 150,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "sold",
                                                                    headerName:
                                                                        "Vendido",
                                                                    description:
                                                                        "Vendido",
                                                                    width: 100,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            if (
                                                                                params.value
                                                                            ) {
                                                                                return "Vendido";
                                                                            } else {
                                                                                return "No vendido";
                                                                            }
                                                                        },
                                                                },

                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "status",
                                                                    headerName:
                                                                        "Estado",
                                                                    description:
                                                                        "Estado",
                                                                    width: 100,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "type",
                                                                    headerName:
                                                                        "Fuente",
                                                                    description:
                                                                        "Fuente",
                                                                    width: 100,
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "name",
                                                                    headerName:
                                                                        "Nombre cliente",
                                                                    description:
                                                                        "Nombre cliente",
                                                                    width: 200,
                                                                },
                                                            ]}
                                                            icon={<Sell />}
                                                            filters={[
                                                                "Fecha",
                                                                "Vendedor",
                                                                "Estado",
                                                                "Fuente",
                                                            ]}
                                                            title="Ventas"
                                                            source="sales"
                                                            dateField="date_sale"
                                                            userId={
                                                                selectedUser
                                                                    ? selectedUser._id
                                                                    : rol ==
                                                                        "super" ||
                                                                        rol ==
                                                                        "comercial"
                                                                        ? "all"
                                                                        : usuario._id
                                                            }
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <GraphItem
                                                            groupId={
                                                                selectedGroup
                                                                    ? selectedGroup._id
                                                                    : undefined
                                                            }
                                                            columns={[
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "createdAt",
                                                                    headerName:
                                                                        "Fecha de cierre",
                                                                    description:
                                                                        "Fecha de cierre",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return moment(
                                                                                params.value
                                                                            ).format(
                                                                                "L LT"
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "vendedor",
                                                                    headerName:
                                                                        "Vendedor",
                                                                    description:
                                                                        "Vendedor",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return (
                                                                                params
                                                                                    .row
                                                                                    .agent_name +
                                                                                " " +
                                                                                params
                                                                                    .row
                                                                                    .agent_lastname
                                                                            );
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "prospect_name",
                                                                    headerName:
                                                                        "Nombre",
                                                                    description:
                                                                        "Nombre",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "prospect_lastname",
                                                                    headerName:
                                                                        "Apellido",
                                                                    description:
                                                                        "Appellido",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "from",
                                                                    headerName:
                                                                        "Anterior",
                                                                    description:
                                                                        "Cierre anterior",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                                {
                                                                    disableColumnMenu:
                                                                        true,
                                                                    sortable:
                                                                        false,
                                                                    field: "to",
                                                                    headerName:
                                                                        "Cierre",
                                                                    description:
                                                                        "Cierre",
                                                                    width: 150,
                                                                    renderCell:
                                                                        (
                                                                            params
                                                                        ) => {
                                                                            return params.value;
                                                                        },
                                                                },
                                                            ]}
                                                            icon={
                                                                <Inventory2 />
                                                            }
                                                            filters={[
                                                                "Fecha",
                                                                "Agente",
                                                                "Anterior",
                                                                "Cierre",
                                                            ]}
                                                            title="Cierres"
                                                            source="closes"
                                                            dateField="createdAt"
                                                            userId={
                                                                selectedUser
                                                                    ? selectedUser._id
                                                                    : rol ==
                                                                        "super" ||
                                                                        rol ==
                                                                        "comercial"
                                                                        ? "all"
                                                                        : usuario._id
                                                            }
                                                        />
                                                    </div>
                                                    {getCompany() &&
                                                        getCompany()
                                                            .groupType ==
                                                        "facturacion" && (
                                                            <div className="w-full">
                                                                <GraphItem
                                                                    groupId={
                                                                        selectedGroup
                                                                            ? selectedGroup._id
                                                                            : undefined
                                                                    }
                                                                    columns={[
                                                                        {
                                                                            disableColumnMenu:
                                                                                true,
                                                                            sortable:
                                                                                false,
                                                                            field: "arrangedDate",
                                                                            headerName:
                                                                                "Fecha y hora",
                                                                            description:
                                                                                "Fecha y hora de envío",
                                                                            width: 150,
                                                                            renderCell:
                                                                                (
                                                                                    params
                                                                                ) => {
                                                                                    return (
                                                                                        moment(
                                                                                            new Date(
                                                                                                params.value
                                                                                            )
                                                                                        ).format(
                                                                                            "L"
                                                                                        ) +
                                                                                        " " +
                                                                                        moment(
                                                                                            new Date(
                                                                                                params.value
                                                                                            )
                                                                                        ).format(
                                                                                            "LT"
                                                                                        )
                                                                                    );
                                                                                },
                                                                        },
                                                                        {
                                                                            disableColumnMenu:
                                                                                true,
                                                                            sortable:
                                                                                false,
                                                                            field: "agentFirstName",
                                                                            headerName:
                                                                                "Vendedor",
                                                                            description:
                                                                                "Vendedor",
                                                                            width: 150,
                                                                            renderCell:
                                                                                (
                                                                                    params
                                                                                ) => {
                                                                                    return params.value
                                                                                        ? params.value +
                                                                                        " " +
                                                                                        params
                                                                                            .row
                                                                                            .agentLastName
                                                                                        : "";
                                                                                },
                                                                        },
                                                                        {
                                                                            disableColumnMenu:
                                                                                true,
                                                                            sortable:
                                                                                false,
                                                                            field: "visits",
                                                                            headerName:
                                                                                "Estado",
                                                                            description:
                                                                                "Estado",
                                                                            width: 125,
                                                                            renderCell:
                                                                                (
                                                                                    params
                                                                                ) => {
                                                                                    let title =
                                                                                        params.value &&
                                                                                            params
                                                                                                .value
                                                                                                .length >
                                                                                            0
                                                                                            ? params
                                                                                                .value[
                                                                                                params
                                                                                                    .value
                                                                                                    .length -
                                                                                                1
                                                                                            ]
                                                                                                .status
                                                                                            : "";
                                                                                    if (
                                                                                        title ==
                                                                                        "executed"
                                                                                    ) {
                                                                                        return "Realizada";
                                                                                    } else if (
                                                                                        title ==
                                                                                        "pending"
                                                                                    ) {
                                                                                        return "Pendiente";
                                                                                    } else {
                                                                                        return title;
                                                                                    }
                                                                                },
                                                                        },
                                                                        {
                                                                            disableColumnMenu:
                                                                                true,
                                                                            sortable:
                                                                                false,
                                                                            field: "createdAt",
                                                                            headerName:
                                                                                "Fecha creación",
                                                                            description:
                                                                                "Fecha y hora de creación",
                                                                            width: 150,
                                                                            renderCell:
                                                                                (
                                                                                    params
                                                                                ) => {
                                                                                    return (
                                                                                        moment(
                                                                                            new Date(
                                                                                                params.value
                                                                                            )
                                                                                        ).format(
                                                                                            "L"
                                                                                        ) +
                                                                                        " " +
                                                                                        moment(
                                                                                            new Date(
                                                                                                params.value
                                                                                            )
                                                                                        ).format(
                                                                                            "LT"
                                                                                        )
                                                                                    );
                                                                                },
                                                                        },
                                                                        {
                                                                            disableColumnMenu:
                                                                                true,
                                                                            sortable:
                                                                                false,
                                                                            field: "contactName",
                                                                            headerName:
                                                                                "Contacto",
                                                                            description:
                                                                                "Contacto",
                                                                            width: 150,
                                                                            renderCell:
                                                                                (
                                                                                    params
                                                                                ) => {
                                                                                    return (
                                                                                        params
                                                                                            .row
                                                                                            ?.prospect
                                                                                            ?.firstName +
                                                                                        " " +
                                                                                        params
                                                                                            .row
                                                                                            ?.prospect
                                                                                            ?.lastName
                                                                                    );
                                                                                },
                                                                        },

                                                                        {
                                                                            disableColumnMenu:
                                                                                true,
                                                                            sortable:
                                                                                false,
                                                                            field: "contactPhone",
                                                                            headerName:
                                                                                "Télefono",
                                                                            description:
                                                                                "Télefono",
                                                                            width: 150,
                                                                            renderCell:
                                                                                (
                                                                                    params
                                                                                ) => {
                                                                                    return params
                                                                                        .row
                                                                                        ?.prospect
                                                                                        ?.phones[0];
                                                                                },
                                                                        },
                                                                        {
                                                                            disableColumnMenu:
                                                                                true,
                                                                            sortable:
                                                                                false,
                                                                            field: "source",
                                                                            headerName:
                                                                                "Origen",
                                                                            description:
                                                                                "Origen",
                                                                            width: 150,
                                                                            renderCell:
                                                                                (
                                                                                    params
                                                                                ) => {
                                                                                    return params
                                                                                        .row
                                                                                        ?.prospect
                                                                                        ?.source[0];
                                                                                },
                                                                        },
                                                                    ]}
                                                                    icon={
                                                                        <TransferWithinAStation />
                                                                    }
                                                                    filters={[
                                                                        "Fecha",
                                                                    ]}
                                                                    title="Visitas"
                                                                    source="visits"
                                                                    dateField="arrangedDate"
                                                                    userId={
                                                                        selectedUser
                                                                            ? selectedUser._id
                                                                            : rol ==
                                                                                "super" ||
                                                                                rol ==
                                                                                "comercial"
                                                                                ? "all"
                                                                                : usuario._id
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                            </Module>
                                        </div>
                                    </div >
                                );
                                break;
                            case 'meetings': {
                                let user_usando_per = selectedUser
                                    ? selectedUser._id
                                    : rol == "super" || rol == "comercial"
                                        ? undefined
                                        : usuario._id;

                                let usuarios_a_mostrar_per = selectedGroup
                                    ? selectedGroup.users.map((u) => u._id)
                                    : user_usando_per;

                                return <MeetingsReport
                                    group={selectedGroup?._id}
                                    userSelected={usuarios_a_mostrar_per}
                                />
                            }
                            case "reportlist":
                                return (
                                    <></>
                                );
                            case "tab":
                                return (
                                    <DashboardCharts
                                        key={p._id}
                                        user={
                                            selectedUser
                                                ? selectedUser
                                                : selectedGroup
                                                    ? {
                                                        ...usuario,
                                                        group: selectedGroup,
                                                    }
                                                    : usuario
                                        }
                                    />
                                );
                            case "lastcall":
                                return ''
                            // return !selectedUser ? (
                            //     <LastCall key={p._id} />
                            // ) : (
                            //     <LastCall
                            //         key={p._id}
                            //         selectedUser={selectedUser}
                            //     />
                            // );
                            case "training":
                                return "";
                            //     return <TrainingPanelModule user={selectedUser ? selectedUser : usuario}/>
                            case "performanceGroup":
                                return ""
                            //     // return <LastCallGroup key={p._id} groupFilter={selectedGroup ? selectedGroup._id : undefined}/>
                            //     let user_usando_list = selectedUser
                            //         ? selectedUser._id
                            //         : rol == "super" || rol == "comercial"
                            //             ? undefined
                            //             : usuario._id;
                            //     let usuarios_a_mostrarlist = selectedGroup
                            //         ? selectedGroup.users.map((u) => u._id)
                            //         : user_usando_list;

                            //     return (
                            //         <>
                            //             <StatusCard
                            //                 key={p._id}
                            //                 user={usuarios_a_mostrarlist}
                            //                 groupFilter={
                            //                     selectedGroup
                            //                         ? selectedGroup._id
                            //                         : undefined
                            //                 }
                            //             />
                            //         </>
                            //     );
                            case "meta":
                                let user_usando = selectedUser
                                    ? selectedUser
                                    : rol == "super" || rol == "comercial"
                                        ? undefined
                                        : usuario;
                                let usuarios_a_mostrar = selectedGroup
                                    ? { ...usuario, group: selectedGroup }
                                    : user_usando;

                                return (
                                    <Module
                                        cardStyle="md:mx-6 mx-2"
                                        title="Metas establecidas"
                                        action={
                                            isAuth() && (isAuth().roles.includes('comercial') || isAuth().roles.includes('super')) ?
                                                <NextLink href={"/comercial/goals"}>
                                                    <ToggleButton
                                                        href="#"
                                                        value=""
                                                        sx={{
                                                            py: 0.25,
                                                            color: "#fff",
                                                            borderColor: "#0080ff",
                                                            background: "#0080ff",
                                                            "&:hover": {
                                                                background:
                                                                    "#0080ff",
                                                                color: "#fff",
                                                            },
                                                        }}
                                                    >
                                                        <CloudUpload />
                                                        <span className="hidden md:block ml-1">Subir/Actualizar metas</span>
                                                    </ToggleButton>
                                                </NextLink>
                                                : ''
                                        }
                                    >
                                        <Goal
                                            key={p._id}
                                            seller={!user_usando}
                                            group={!user_usando}
                                            user={usuarios_a_mostrar}
                                        />
                                    </Module>
                                );
                            case "performance":
                                let user_usando_per = selectedUser
                                    ? selectedUser._id
                                    : rol == "super" || rol == "comercial"
                                        ? undefined
                                        : usuario._id;
                                let usuarios_a_mostrar_per = selectedGroup
                                    ? selectedGroup.users.map((u) => u._id)
                                    : user_usando_per;

                                let alertaBillsUserP = _.get(getCompany(), 'alertPayYourBills')
                                let rolesUserP = _.get(isAuth(), 'roles', [])
                                let alertaBillsP = false
                                if (alertaBillsUserP) {
                                    if (rolesUserP.includes(alertaBillsUserP)) {
                                        alertaBillsP = true
                                    } else if (alertaBillsUserP == 'super' && rolesUserP.includes('comercial')) {
                                        alertaBillsP = true
                                    } else if (alertaBillsUserP == 'user') {
                                        alertaBillsP = true
                                    }
                                }
                                let alertaBillsSeveretyP = _.get(getCompany(), 'alertPayYourBillsSeverity', 'low')
                                return (
                                    <>
                                        {/* <div className="flex flex-col">
                                            <div className="mb-2">
                                                {
                                                    typeof usuarios_a_mostrar_per != 'object' && (user_usando_per || _.get(isAuth(), 'roles', []).indexOf('comercial') < 0) &&
                                                    <Trophy
                                                        currentUser={usuario}
                                                        userSelected={selectedUser}
                                                        user={usuarios_a_mostrar_per}
                                                    />
                                                }
                                            </div>
                                        </div> */}

                                        {
                                            // <Grid item mb={2}>
                                            alertaBillsP && <div className="mx-8" style={{ marginBottom: '16px' }}>
                                                <Alert
                                                    severity={alertaBillsSeveretyP == 'low' ? 'warning' : "error"}
                                                    sx={alertaBillsSeveretyP == 'high' ?
                                                        { background: '#F99', border: '2px solid #d66', color: 'dark', fontSize: '18px' }
                                                        : alertaBillsSeveretyP == 'medium' ?
                                                            {}
                                                            : {}
                                                    }
                                                    icon={<ErrorIcon sx={alertaBillsSeveretyP == 'high' ? { color: 'dark', fontSize: '34px' } : {}} />}
                                                >
                                                    <Linkify>
                                                        <b>Alerta de facturación: </b>
                                                        {_.get(getCompany(), 'alertPayYourBillsDescription',
                                                            'Consulta el estado de tus facturas y paga a tiempo para evitar penalizaciones y suspensión de tu servicio. Consultar con tu ejecutivo comercial.')}
                                                    </Linkify>
                                                </Alert>
                                                {/* </Grid> */}
                                            </div>
                                        }

                                        <Performance
                                            key={p._id}
                                            user={usuarios_a_mostrar_per}
                                            selectedUserSyncHistory={selectedUser ?? ''}
                                        />
                                    </>
                                );
                            default:
                                return (
                                    <Module cardStyle="mx-8" title={p.title}>
                                        <p className="ml-3 mb-3">
                                            {JSON.stringify(p)}
                                        </p>
                                    </Module>
                                );
                        }
                    })
                    .filter((ele) => ele != "")
                    .map((ele) => (
                        // <Grid item>{ele}</Grid>
                        <div>{ele}</div>
                    ))}
            </div >
        </section>
    );
}

const mapStateToProps = (state) => ({
    users: state.main.users,
    selectedUser: state.main.selected_user,
    selectedGroup: state.main.selected_group,
    usarAyer: state.main.usarAyer,
});

const mapDispatchToProps = {
    setUsers: setUsers,
    setSelectedUser: setSelectedUser,
    setUsarAyer: setUsarAyer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
