import { Feature, isFeatureEnabled } from "@/helpers/featureFlagHelpers";
import { getStatusTagColorToHexadecimal } from "@/helpers/tagColorToPalette";


export const chartsManagement = ({ company }) => ({
    'contactsTaken': {
        title: 'Contactos tomados',
        source: 'ventiacontacts',
        group: 'default',
        groupby: 'createdAt',
        colorby: 'originText',
        sumtype: 'count',
        sumfield: '_id',
        userkey: 'firstAgent',
        userAs: 'id',
        fillextralabels: true,
        ignoreunusedlabels: true,
        labels: company.origins.map((or) => ({ key: or.code, name: or.name })),
        match: { origin: { $nin: company.origins.map(o => o.code) } }
    },
    'contactsCreated': {
        title: 'Contactos creados',
        source: 'ventiacontacts',
        group: 'default',
        groupby: 'createdAt',
        colorby: 'originText',
        sumtype: 'count',
        sumfield: '_id',
        userkey: 'firstAgent',
        userAs: 'id',
        fillextralabels: true,
        ignoreunusedlabels: true,
        labels: company.origins ? company.origins.map((ar) => ({ key: ar.code, name: ar.name })) : [],
        match: { origin: { $in: company.origins.map(o => o.code) } }
    },
    'tracings': {
        title: 'Seguimientos',
        source: 'tracings',
        group: 'default',
        groupby: 'dateDate',
        colorby: 'validity',
        sumtype: 'count',
        sumfield: '_id',
        colors: ["#7f7f7f", "#FFD730", "#feaf1a", "#ff4560", "#00e395"],
        labels: [
            { key: "not_client", name: "Sin cliente", data: [] },
            { key: "not_answer", name: "Sin respuesta", data: [] },
            { key: "not_time", name: "Sin tiempo", data: [] },
            { key: "not_record", name: "Sin registro", data: [] },
            { key: "valid", name: "Valido", data: [] },
        ]
    },
    'receipts': {
        title: 'Ventas',
        source: 'receipts',
        group: 'default',
        groupby: 'date_sale',
        colorby: 'status',
        sumtype: 'count',
        sumfield: '_id',
        match: { sold: true }
    },
    'salesEntered': {
        title: 'Ingresadas',
        source: 'receipts',
        group: 'default',
        groupby: 'date',
        colorby: 'status',
        sumtype: 'count',
        sumfield: '_id',
    },
    'channelSales': {
        title: 'Ventas por canal',
        source: 'receiptsventiacontact',
        group: 'default',
        groupby: 'date_sale',
        colorby: 'sourceProspect',
        sumtype: 'count',
        sumfield: '_id',
        nulltext: "Sin contacto",
        match: { sold: true },
        fillextralabels: true,
        ignoreunusedlabels: true,
        labels: company.origins ? company.origins.map((ar) => ({ key: ar.code, name: ar.name })) : [],
    },
    'closures': {
        title: 'Cierres',
        source: 'closes',
        group: 'default',
        groupby: 'createdAt',
        colorby: 'to',
        sumtype: 'count',
        sumfield: '_id',
        userkey: 'agent_email',
        userAs: 'email',
        match: { to: { $ne: null } },
        fillextralabels: true,
        ignoreunusedlabels: true,
        labels: company.archivingReasons ? company.archivingReasons.map((ar) => ({ key: ar.code, name: ar.name })) : []
    },
    'minutesOnAir': {
        title: 'Minutos al aire',
        source: 'calls',
        group: 'default',
        groupby: 'timestampDate',
        colorby: 'prospectName',
        colorbyexist: true,
        sumtype: 'sum',
        sumfield: 'duration',
        colors: ["#7f7f7f", "#008ffa"],
        labels: [{ key: false, name: 'No es cliente' }, { key: true, name: 'Cliente' },],
        mapValues: (number) => {
            return typeof number == "number" ? Math.floor(number / 60) : Math.floor(parseInt(number) / 60);
        }
    },
    'calls': {
        title: 'Llamadas',
        source: 'calls',
        group: 'default',
        groupby: 'timestampDate',
        colorby: 'durationRange',
        sumtype: 'count',
        strictLabels: true,
        sumfield: '_id',
        colors: ["#ff4560", "#edbf33", "#FFD730", "#bdcf32", "#87bc45", "#00e395"],
        labels: [
            { key: "not_answer", name: "Sin respuesta", data: [] },
            { key: "lt_10", name: "0:00 a 0:10", data: [] },
            { key: "lt_35", name: "0:10 a 0:35", data: [] },
            { key: "lt_90", name: "0:35 a 1:30", data: [] },
            { key: "lt_180", name: "1:30 a 3:00", data: [] },
            { key: "ge_180", name: "Mayor a 3 min", data: [] },
        ]
    },
    'redesChats': isFeatureEnabled(Feature.CHANNELS) ? {
        title: 'Chats redes',
        source: 'chats',
        group: 'default',
        groupby: 'createdAt',
        colorby: 'content.medium',
        sumtype: 'count',
        sumfield: '_id',
        labels: [
            { key: 'fb', name: 'Facebook' },
            { key: 'ig', name: 'Instagram' }
        ],
        match: { "content.medium": { $ne: 'leads' } }
    } : {},
    'whatsAppChats': isFeatureEnabled(Feature.CHANNELS) ? {
        title: 'Chats Whatsapp',
        source: 'chats-wpp',
        group: 'default',
        groupby: 'createdAt',
        colorby: 'content.channelName',
        sumtype: 'count',
        sumfield: '_id',
        nulltext: 'Whatsapp Personal'
    } : {},
    'contactAttention': {
        title: 'Atención de contactos',
        source: 'contacts',
        group: 'default',
        groupby: 'createdAt',
        colorby: 'stats.attention',
        sumtype: 'count',
        sumfield: '_id',
        userkey: 'agent.email',
        userAs: 'email',
        fillextralabels: true,
        labels: true ? [
            { key: 'not_actions', name: 'Sin acciónes' },
            { key: 'chat', name: 'Chat' },
            { key: 'call', name: 'Llamada' },
            { key: 'call_chat', name: 'Llamada + Chat' },
            { key: 'tracing', name: 'Seguimiento' },
            { key: 'tracing_chat', name: 'Seguimiento + Chat' },
        ] : [
            { key: 'not_actions', name: 'Sin acciónes' },
            { key: 'call', name: 'Llamada' },
            { key: 'tracing', name: 'Seguimiento' },
        ],
    },
    'visits': {
        title: 'Visitas',
        source: 'visits',
        group: 'default',
        groupby: 'createdAt',
        colorby: 'content.state',
        sumtype: 'count',
        sumfield: '_id',
        userkey: 'agent',
        userAs: 'id',
        fillextralabels: true,
        ignoreunusedlabels: true,
        labels: company.visitResults ? company.visitResults.map((ar) => ({ key: ar.code, name: ar.name })) : [],
    },
    'whatsAppNotes': {
        title: 'Notas Whatsapp',
        source: 'whatsapp-notes',
        group: 'default',
        groupby: 'createdAt',
        nulltext: "Whatsapp",
        sumtype: 'count',
        userkey: 'agent',
        userAs: 'id',
        sumfield: '_id',
    },
    'tagChange': {
        title: 'Cambios de etiqueta',
        source: 'tagChange',
        group: 'default',
        groupby: 'createdAt',
        nulltext: "Sin cambios de etiqueta",
        strictLabels: true,
        sumtype: 'count',
        userkey: 'agent',
        userAs: 'id',
        colorby: 'content.to',
        sumfield: '_id',
        fillextralabels: true,
        ignoreunusedlabels: true,
        colors: (company?.statusTags || []).filter((tag) => tag.active).map((tag) => getStatusTagColorToHexadecimal(tag.color, 'primary')),
        labels: (company?.statusTags || []).filter((tag) => tag.active).map((statusTag) => ({
            key: statusTag.code,
            name: statusTag.name,
            data: []
        }))
    },
    'contactsState': {
        title: 'Estado de contacto',
        nulltext: "Sin contactos",
        source: 'contactsState',
        groupby: 'createdAt',
        group: 'default',
        sumtype: 'count',
        userkey: 'agent',
        userAs: 'id',
        colorby: 'status',
        sumfield: '_id',
        strictLabels: true,
        fillextralabels: true,
        ignoreunusedlabels: true,
        colors: ["#01D0E4", "#FE9900", "#65E732", "#5DE2E7", "#9F5DE9", "#FEAD6F", "#F44336"],
        labels: [
            { key: 'new', name: 'Nuevo' },
            { key: 'closed', name: 'Cerrado' },
            { key: 'sold', name: 'Vendido' },
            { key: 'reminder', name: 'Recordatorio' },
            { key: 'active', name: 'Activo' },
            { key: 'overdue_reminder', name: 'Recordatorio Vencido' },
            { key: 'disregarded', name: 'Desatendido' }
        ]
    },
    'contactStatusTag': {
        active: (company?.statusTags || []).length > 0,
        title: 'Contactos con etiquetas activas',
        nulltext: "Sin contactos",
        groupby: 'createdAt',
        colorby: 'statusTag',
        source: 'contactStatusTag',
        strictLabels: true,
        group: 'default',
        sumtype: 'count',
        userkey: 'agent',
        userAs: 'id',
        sumfield: '_id',
        fillextralabels: true,
        ignoreunusedlabels: true,
        colors: (company?.statusTags || []).filter((tag) => tag.active).map((tag) => getStatusTagColorToHexadecimal(tag.color, 'primary')),
        labels: (company?.statusTags || []).filter((tag) => tag.active).map((statusTag) => ({
            key: statusTag.code,
            name: statusTag.name,
            data: []
        }))
    },
    'closedContactsCount': {
        title: 'Contactos cerrados',
        source: 'closedContactsCount',
        colorby: 'archivingReason',
        nulltext: "Sin contactos",
        groupby: 'createdAt',
        group: 'default',
        sumtype: 'count',
        userkey: 'agent',
        userAs: 'id',
        sumfield: '_id',
        labels: (company?.archivingReasons || []).filter((a) => a.active).map((a) => {
            return {
                key: a.code,
                name: a.name
            }
        })
    },
    'vistCountToContacts': {
        active: ((company?.visitResults || []).length > 0),
        source: 'vistCountToContacts',
        title: 'Visitas a clientes',
        groupby: 'createdAt',
        nulltext: "Visitas",
        group: 'default',
        sumtype: 'sum',
        userkey: 'agent',
        sumfield: 'stats.visits',
        userAs: 'id'
    },
    'contactAdditionalData': (company?.additionalDataFields || [])
        .filter((field) => (field.active && field.type == 'select'))
        .map((field) => {
            return {
                title: `Contactos con ${field?.name?.toLowerCase()}`,
                colorby: `additionalData.${field.code}`,
                source: 'contactAdditionalData',
                nulltext: field.name,
                groupby: 'createdAt',
                group: 'default',
                sumtype: 'count',
                userkey: 'agent',
                userAs: 'id',
                sumfield: '_id',
                labels: field.options.map((option) => ({
                    key: option,
                    name: option
                }))
            }
        })
})

export const chartsSales = () => ({
    'dailySalesSinceInception': {
        title: 'Ventas por dia desde ingreso',
        id: 'ventas_por_dia_ingreso',
        source: 'receiptsventiacontact',
        group: 'default',
        groupby: 'date_sale',
        colorby: 'dia_ingreso',
        sumtype: 'count',
        sumfield: '_id',
        match: { sold: true },
        nulltext: "Sin contacto",
        labels: [
            { key: "not_prospect", name: "Sin contacto" },
            { key: "less_1_day", name: "0-1 dia" },
            { key: "less_3_days", name: "2-3 dias" },
            { key: "less_7_days", name: "4-7 dias" },
            { key: "less_15_days", name: "8-15 dias" },
            { key: "less_30_days", name: "16-30 dias" },
            { key: "less_60_days", name: "31-60 dias" },
            { key: "more_60_days", name: "61 dias o mas" },
        ]
    },
    'salesType': {
        title: 'Ventas por tipo',
        source: 'receiptsventiacontact',
        group: 'default',
        groupby: 'date_sale',
        nulltext: "Sin tipo",
        colorby: 'type',
        sumtype: 'count',
        sumfield: '_id',
        match: { sold: true }
    },
    'salesByDepartment': {
        title: 'Ventas por estado/departamento',
        id: 'ventas_estado',
        source: 'receiptsventiacontact',
        group: 'default',
        groupby: 'date_sale',
        nulltext: "Sin datos",
        colorby: 'state',
        sumtype: 'count',
        sumfield: '_id',
        match: { sold: true }
    },
    'salesByCity': {
        title: 'Ventas por ciudad',
        id: 'ventas_ciudad',
        source: 'receiptsventiacontact',
        group: 'default',
        groupby: 'date_sale',
        colorby: 'city',
        sumtype: 'count',
        sumfield: '_id',
        match: { sold: true }
    },
    'salesByProduct': {
        title: 'Ventas por producto',
        id: 'ventas_producto',
        source: 'receiptsventiacontact',
        group: 'default',
        groupby: 'date_sale',
        colorby: 'product',
        nulltext: "Sin producto",
        sumtype: 'count',
        sumfield: '_id',
        match: { sold: true }
    },
    'salesByCatagorie': {
        title: 'Ventas por categoria',
        id: 'ventas_categoria',
        source: 'receiptsventiacontact',
        group: 'default',
        groupby: 'date_sale',
        colorby: 'category',
        nulltext: "Sin categoria",
        sumtype: 'count',
        sumfield: '_id',
        match: { sold: true }
    }
})

export const chartsClosures = ({ company }) => ({
    'closuresByChannel': {
        title: 'Cierres por canal contacto',
        id: 'contacts_cierres_canal',
        source: 'closescontact',
        group: 'default',
        groupby: 'createdAt',
        colorby: 'sourceProspect',
        sumtype: 'count',
        sumfield: '_id',
        userkey: 'agent_email',
        userAs: 'email',
        nulltext: "Sin canal",
        fillextralabels: true,
        ignoreunusedlabels: true,
        labels: company.origins ? company.origins.map((ar) => ({ key: ar.code, name: ar.name })) : [],
    },
    'closuresPerContactAttention': {
        title: 'Cierres por atención a contacto',
        id: 'cierres_attention_group',
        source: 'closescontact',
        group: 'default',
        groupby: 'createdAt',
        colorby: 'prospect.stats.attention',
        sumtype: 'count',
        sumfield: '_id',
        nulltext: "Sin atención",
        userkey: 'agent_email',
        userAs: 'email',
        fillextralabels: true,
        ignoreunusedlabels: true,
        labels: [
            { key: 'not_actions', name: 'Sin acciónes' },
            { key: 'chat', name: 'Chat' },
            { key: 'call', name: 'Llamada' },
            { key: 'call_chat', name: 'Llamada + Chat' },
            { key: 'tracing', name: 'Seguimiento' },
            { key: 'tracing_chat', name: 'Seguimiento + Chat' },
        ],
    },
    'closuresPerDayInception': {
        title: 'Cierres por dia desde ingreso del contacto',
        id: 'cierres_por_dia_ingreso',
        source: 'closescontact',
        group: 'default',
        groupby: 'createdAt',
        colorby: 'dia_ingreso',
        sumtype: 'count',
        sumfield: '_id',
        nulltext: "Sin contacto",
        labels: [
            { key: "not_prospect", name: "Sin contacto" },
            { key: "less_1_day", name: "0-1 dia" },
            { key: "less_3_days", name: "2-3 dias" },
            { key: "less_7_days", name: "4-7 dias" },
            { key: "less_15_days", name: "8-15 dias" },
            { key: "less_30_days", name: "16-30 dias" },
            { key: "less_60_days", name: "31-60 dias" },
            { key: "more_60_days", name: "61 dias o mas" },
        ]
    }
})

export const chartsClients = ({ company }) => ({
    'totalClient': {
        title: 'Clientes',
        id: 'contacts_total',
        source: 'contacts',
        group: 'default',
        groupby: 'createdAt',
        colorby: 'sourceProspect',
        sumtype: 'count',
        sumfield: '_id',
        userkey: 'agent.email',
        userAs: 'email',
        nulltext: "Sin origen",
        fillextralabels: true,
        ignoreunusedlabels: true,
        labels: company.origins.map((or) => ({ key: or.code, name: or.name })),
    }
})

export const allChartsType = ({ company }) => ({
    ...chartsManagement({ company }),
    ...chartsSales(),
    ...chartsClosures({ company }),
    ...chartsClients({ company })
})