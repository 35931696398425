import { Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dynamic from "next/dynamic";
import { useMemo, useState } from "react";
import { getGraphData } from '../../actions/graph';
import defaultBar from './defaultOptions/bar';
import pie from "./defaultOptions/pie";
import GraphDetail from "./GraphDetail";
import getModel from './sourceModels/getModel';


const Chart = dynamic(() => import('react-apexcharts'), { ssr: false });

export interface GraphInterface {
  source: string,
  group: 'date' | 'week' | 'monthyear' | 'year' | 'text' | 'default' | 'user' | 'group' | 'total',
  groupby: string,
  colorby: string,
  sumtype: 'sum' | 'count' | 'count_unique' | 'default',
  sumfield: string,
  userAs?: 'email' | 'id',
  labels?: { key: string | boolean, name: string, data?: any[] }[]
  optionallabels?: { key: string | boolean, name: string, data?: any[] }[]
  testing?: boolean,
  title: string,
  id?: string,
  datefilter?: { gt?: string, lt?: string }
  userfilter?: string[],
  fillextralabels?: boolean,
  ignoreunusedlabels?: boolean,
  colorbyexist?: boolean,
  colors?: string[],
  strictLabels?: boolean,
  userkey?: string,
  percentage?: boolean,
  updateVersion?: number,
  activeUsers?: any,
  nulltext?: string,
  match?: any,
  groupsUse?: any[],
  groupTitle?: string,
  valueOption?: boolean,
  mapValues?: (name: (string | number)) => string | number
}




export default function Graph(config: GraphInterface) {
  var [debugData, setDebugData] = useState([])
  var [totals, setTotals] = useState([])
  var [modal, setModal] = useState<{ open: boolean, series: string | undefined, category: string | undefined, categoryText: string | undefined, seriesText: string | undefined }>({ open: false, series: undefined, category: undefined, categoryText: undefined, seriesText: undefined })



  const reloadAnnotations = (chartContext: any, config, labels: any, percentageFlag?: boolean) => {
    try {
      const seriesTotals = config.globals.stackedSeriesTotals;
      if (config.globals.labels.length != seriesTotals.length) return ''
      let points: any;
      if (percentageFlag && labels.length > 0) {
        let total_serie = new Array(labels[0].data.length).fill(0)
        labels.map((serie) => {
          serie.data.map((val_serie, index_serie) => total_serie[index_serie] += val_serie)
        })
        points = config.globals.labels.map((label, i) => (
          {
            x: label,
            y: seriesTotals[i],
            marker: {
              size: 0,
            },
            label: {
              style: {
                background: "rgba(0,0,0,0)",
              },
              borderColor: 'rgba(0,0,0,0)',
              text: Math.round(total_serie[i] as number) + ""
            }
          })
        ).filter((_, i) => seriesTotals[i] != 0)

      } else {
        points = config.globals.labels.map((label, i) => (
          {
            x: label,
            y: seriesTotals[i],
            marker: {
              size: 0,
            },
            label: {
              style: {
                background: "rgba(0,0,0,0)",
              },
              borderColor: 'rgba(0,0,0,0)',
              text: Math.round(seriesTotals[i] as number) + ""
            }
          })
        ).filter((_, i) => seriesTotals[i] != 0)
      }


      if (seriesTotals.length == totals.length) {
        if (seriesTotals.filter((v, i) => totals[i] != v).length == 0) return ''
      }
      setTotals(seriesTotals)
      totals = seriesTotals
      try {
        ApexCharts.exec(config.globals.chartID, 'clearAnnotations')
      } catch (err) {
        return console.log('El grafico no esta siendo renderizado')
      }

      if (percentageFlag) {
        ApexCharts.exec(config.globals.chartID, 'updateOptions', {
          dataLabels: {
            enabled: true,
            formatter: function (value, _) {
              if (typeof value == 'number') return Math.floor(value) + "%"
              if (typeof value == "object") return value
              let alt_value = parseInt(value as string)
              if (isNaN(alt_value)) return value
              return Math.floor(alt_value) + "%";
            }
          },
          annotations: {
            points
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: (val, opts) => {
                let total = 0
                opts.series.map((val) => total += val[opts.dataPointIndex])
                return `${Math.round(val)}% (${labels[opts.seriesIndex].data[opts.dataPointIndex]})`
              }
            }
          },
        })
      } else {
        ApexCharts.exec(config.globals.chartID, 'updateOptions', {
          dataLabels: {
            enabled: true,
            formatter: function (value, _) {
              if (!value) return 0
              if (typeof value == 'number') return Math.floor(value)
              if (typeof value == "object") return value
              let alt_value = parseInt(value as string)
              if (isNaN(alt_value)) return value
              return Math.floor(alt_value);
            }
          },
          annotations: {
            points
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: (val, opts) => {
                let total = 0
                opts.series.map((val) => total += val[opts.dataPointIndex])
                let percent = Math.floor((val / total) * 100)
                return `${Math.floor(val)} (${percent}%)`
              }
            }
          },
        })
      }
    } catch (err) {
      console.log('Hubo un error al reloadear las anotaciones')
    }
  }

  const reload = async (versionReload: number) => {
    let result = await getGraphData(config, 'receipt')

    if (!result.series || result.series.length == 0 || result.series[0] == null) {
      result.series = []
    }

    if (result.series) {
      let series = [...result.series];
  
      if (config.mapValues) {
        series = series.map((s) => {
          if (s.data) {
            s.data = s.data.map(config.mapValues)
          }
          return s
        })
      }

      if (config.labels) {
        let extra_labels = result.series.map((s) => s.name)
        series = config.labels.map((cl) => {
          let l = { data: [], ...cl }
          let clearLength = 0
          result.series.map((s) => {
            if (s.name == l.key) {
              l.data = s.data
              extra_labels.splice(extra_labels.indexOf(s.name), 1)
            }
            if (s.data.length > clearLength) {
              clearLength = s.data.length
            }
          })
          if (l.data.length == 0 && config.ignoreunusedlabels) return false

          if (l.data.length == 0) {
            l.data = new Array(clearLength).fill(0)
          }

          return l
        }).filter((s) => s)

        if (config.optionallabels) {
          series = [...series, ...config.optionallabels.map((cl) => {
            let l = { data: [], ...cl }
            result.series.map((s) => {
              if (s.name == l.key) {
                l.data = s.data
                extra_labels.splice(extra_labels.indexOf(s.name), 1)
              }
            })
            if (l.data.length == 0) return false

            return l
          }).filter((cl) => cl)]
        }

        if (config.fillextralabels) {
          series = [...series, ...extra_labels.map((lbl) => {
            let l: any = { key: lbl, name: lbl, data: [] }
            result.series.map((s) => {
              if (s.name == l.key) {
                l.data = s.data
              }
            })
            return l
          })]
        }
      }

      if (result.xaxis) {
        let original_xaxis = result.xaxis.categories

        let percentageFlag = config.percentage

        let optionsWithEvents = { ...defaultBar({ ...config, disableAnimation: result.xaxis.categories.length > 20 }, [], versionReload) }


        let newOptions = { ...optionsWithEvents, xaxis: result.xaxis };

        if (config.group === 'user' && newOptions.xaxis && newOptions.xaxis.categories) {
          newOptions.xaxis.categories = newOptions.xaxis.categories.map((userlabel) => {
            let user_model = config.activeUsers.findIndex((u) => u._id == userlabel || u.email == userlabel)
            if (user_model >= 0) {
              return config.activeUsers[user_model].name + " " + config.activeUsers[user_model].lastname
            } else {
              return (userlabel && userlabel != 'undefined') ? userlabel : 'Sin usuario'
            }
          })
        }

        if (config.group === 'group' && config.groupsUse && config.groupsUse.length > 0) {
          let lessAncestors = config.groupsUse[0].ancestors.length;
          config.groupsUse.map((g) => {
            if (lessAncestors > g.ancestors.length) lessAncestors = g.ancestors.length
          })

          let groupBy = config.groupsUse.filter((g) => g.ancestors.length <= (lessAncestors + 1))
          let newCategoriesGroup = groupBy.map((g) => {
            if (lessAncestors == g.ancestors.length) return { ...g, businessName: g.businessName + " (Actual)" }
            let newUsersList = [...g.users]
            config.groupsUse.map((ga) => {
              if (ga.ancestors.length > (lessAncestors + 1)) {
                let group_id = ga.ancestors[lessAncestors + 1]
                if (g._id == group_id) newUsersList = [...newUsersList, ...ga.users]
              }
            })
            return { ...g, users: newUsersList }
          })
          let newSeries = series.map((serie) => {
            original_xaxis = new Array(newCategoriesGroup.length).fill([])
            let data = newCategoriesGroup.map((g, index_g) => {
              let sum = 0
              newOptions.xaxis.categories.map((userlabel, index) => {
                let user_model = g.users.filter((u) => u._id == userlabel || u.email == userlabel)
                if (user_model.length > 0) {
                  sum += serie.data[index]
                  original_xaxis[index_g].push(userlabel)
                }
              })
              return sum
            })

            return { ...serie, data }
          })


          newOptions.xaxis.categories = newCategoriesGroup.map((g) => g.businessName)

          series = newSeries

        }

        if (config.strictLabels && config.labels) {
          let seriesKeys = new Set(series.map((serie) => serie.key));
  
          config.labels.forEach(({ key, name }, inx) => {
            if (!seriesKeys.has(key)) {
              series.splice(inx, 0, { data: [], name, key })
            }
          })
  
          series = series.filter(Boolean);
        };

        var dataPointSelectionFunction = (event: any, chartContext: any, configPoint) => {
          if (configPoint.selectedDataPoints.filter((point) => point && point.length > 0).length == 0) return '';

          setTimeout(() => {
            ApexCharts.exec(chartContext.opts.chart.id, "toggleDataPointSelection", configPoint.seriesIndex, configPoint.dataPointIndex)
          }, 100)

          setModal({
            open: true,
            categoryText: newOptions.xaxis.categories[configPoint.dataPointIndex],
            category: original_xaxis[configPoint.dataPointIndex],
            series: series[configPoint.seriesIndex].key
              ? series[configPoint.seriesIndex].key
              : series[configPoint.seriesIndex].name,
            seriesText: series[configPoint.seriesIndex].name
          })
        }

        newOptions.chart.events = {
          mounted: (chartContext, config) => {
            setTimeout(() => {
              reloadAnnotations(chartContext, config, series, percentageFlag);
            });
          },
          updated: (chartContext, config) => {
            setTimeout(() => {
              reloadAnnotations(chartContext, config, series, percentageFlag);
            });
          },
          dataPointSelection: dataPointSelectionFunction
        }

        if (Array.isArray(config?.colors) && config.colors.length > 0) {
          newOptions = { ...newOptions, colors: config.colors };
        };

        if (config.group != 'total' && config.percentage && series.length > 0) {

          let total_serie = new Array(series[0].data.length).fill(0)
          series.map((serie) => {
            serie.data.map((val_serie, index_serie) => total_serie[index_serie] += val_serie)
          })
          let alt_series = series.map((serie) => {
            return { ...serie, data: serie.data.map((data, index_serie) => (data / (total_serie[index_serie] == 0 ? 1 : total_serie[index_serie])) * 100) }
          })
          return { series: [...alt_series], options: newOptions }

        } else {
          if (config.group == 'total') {
            let data_series = series.map((s) => (s.data[0] != undefined ? s.data[0] : 0))
            let label_list = series.map((s) => (s.name))
            let options_list = pie(config, [], versionReload)
            var dataPointSelectionFunctionTotal = (event: any, chartContext: any, config) => {
              if (event && chartContext) {

              }

              if (config.selectedDataPoints.filter((point) => point && point.length > 0).length == 0) {
                return ''
              }
              setTimeout(() => {
                ApexCharts.exec(chartContext.opts.chart.id, "toggleDataPointSelection", config.dataPointIndex)
              }, 100)
              setModal({ open: true, category: "Periodo", categoryText: "Periodo", series: series[config.dataPointIndex].key ? series[config.dataPointIndex].key : series[config.dataPointIndex].name, seriesText: series[config.dataPointIndex].name })
            }
            options_list.chart.events = {
              dataPointSelection: dataPointSelectionFunctionTotal
            }
            options_list = { ...options_list, xaxis: result.xaxis }
            options_list.labels = label_list
            return { series: [...data_series], options: options_list }
          } else {
            return { series: [...series], options: newOptions }
          }

        }
      }
    }
    // return {series: [], options: defaultBar(config, [], versionReload)}
  }

  let queryGraph = useQuery({
    queryFn: () => reload(config.updateVersion),
    queryKey: ['graph_data', config.source, config.colorby, config.colors, config.datefilter, config.group, config.groupby, config.labels, config.percentage, config.sumtype, config.title, config.updateVersion, config.userfilter, config.match]
  })

  let options: any = queryGraph.data?.options ?? defaultBar(config, [], config.updateVersion);
  const series = queryGraph.data?.series ?? [];

  let loading = queryGraph.isLoading

  const handleCloseModal = () => {
    setModal({ open: false, series: undefined, category: undefined, seriesText: undefined, categoryText: undefined })
  }

  return (
    <>
      <GraphDetail open={modal.open} series={modal.series} seriesText={modal.seriesText} category={modal.category} categoryText={modal.categoryText} config={config} close={handleCloseModal} model={getModel(config.source)} />
      {!loading ?
        <Chart
          height={'350px'}
          type={config.group == 'total' ? "donut" : 'bar'}
          series={series}
          options={options}
        />
        // <div>
        //   {/* type = {config.group == 'total' ? "donut" : 'bar'} */}
        //   series = {JSON.stringify(series,null,2)}
        //   options = {JSON.stringify(options,null,2)}
        // </div>
        :
        <Skeleton variant="rectangular" animation="wave" width={'100%'} sx={{ height: '350px', borderRadius: '10px' }} />
      }
      {/* {JSON.stringify(config.match)} */}
      {config.testing &&
        <p>
          {JSON.stringify(config).replaceAll(',', ', ')}
        </p>}
    </>
  )
}